import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
      authenticationToken: null,
      admin: {}
    },
    mutations: {
      setAuthenticationToken(state, authenticationToken) {
        state.authenticationToken = authenticationToken;
      },
      setAdmin(state, admin) {
        state.admin = admin;
      }
    },
    actions: {},
    getters: {
      isLoggedIn: (state) => {
        return !!state.authenticationToken;
      }
    },
  });

export default store;