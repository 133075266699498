<template>
    <b-nav vertical v-if="this.$route.name !== 'Home' && this.$route.name !== 'Login'">
        <b-nav-item 
            :to="item.href" 
            exact 
            exact-active-class="active"
            v-for="item in navbarItems" :key="item.link">
            {{ item.label }}
        </b-nav-item>
    </b-nav>
</template>
<script>
export default {
    name: "Navbar",
    data() {
        return {
            navbarItems: [
                {
                    href: "/patients",
                    label: "Patients"
                },
                {
                    href: "/doctors",
                    label: "PTs"
                },
                {
                    href: "/offices",
                    label: "Locations"
                },
                {
                    href: "/admins",
                    label: "Admins"
                }
            ]
        }
    }
}
</script>
<style lang="css" scoped>
    @import "./Navbar.css";
</style>