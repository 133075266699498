<template>
  <div class="header">
    <div class="logo"><img class="logo" :src="require('@/assets/img/logo.svg')" /></div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>