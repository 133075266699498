import Vue from 'vue';
import App from './App.vue';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueToastr from 'vue-toastr';
import GAuth from 'vue-google-oauth2';

import router from './router';
import store from './store';

Vue.directive('visible', (el, bind) => {el.style.visibility=(bind.value) ? 'visible' : 'hidden';});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(VueToastr);
Vue.use(GAuth, {clientId: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID});

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
    