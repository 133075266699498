<template>
  <div id="app">
   <Header/>
    <b-container fluid>
      <b-row>
        <b-col cols="1">
          <Navbar/>
        </b-col>
        <b-col cols="11">
          <div class="content">     
            <router-view />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <br>
    <br>
    <br>
    <div class="footer">
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/core/Navbar";
import Header from "@/components/core/Header";
export default {
  components: {
    Header,
    Navbar
  },
  
}
</script>

<style>
  @import './assets/styles/site.css';
  @import "./assets/styles/fontawesome-all.css";
</style>
