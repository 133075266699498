import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/offices',
    name: 'Offices',
    component: () => import('../views/Offices.vue')
  },
  {
    path: '/offices/:id',
    name: 'Office',
    props: true,
    component: () => import('../views/Office.vue')
  },
  {
    path: '/doctors',
    name: 'Doctors',
    component: () => import('../views/Doctors.vue')
  },
  {
    path: '/doctors/:id',
    name: 'Doctor',
    props: true,
    component: () => import('../views/Doctor.vue')
  },
  {
    path: '/patients',
    name: 'patients',
    component: () => import('../views/Patient/PatientsIndex.vue')
  },
  {
    path: '/patients/:id',
    name: 'Patient',
    props: true,
    component: () => import('../views/Patient/PatientEdit.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
